import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import urlParser from '../utils/urlParser';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import Label from '../components/Label';
import linkStripper from '../utils/link-stripper';

const SmallerServicesListContainer = styled.div`
  padding-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const SmallerServicesListContainerInner = styled.div`
  width: 80%;
  // max-width: 1150px;
  @media (max-width: 1250px) {
    width: 90%;
  }
`;

const ServicesListColumnContainerOuterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 860px) {
    margin-top: 50px;
  }
`;

const ServicesListColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1080px;
  @media (max-width: 860px) {
    flex-wrap: wrap;
  }
`;

const Column = styled.div`
  @media (max-width: 860px) {
    width: 100%;
  }
`;

const ColumnTitle = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 59px;
  margin-bottom: 10px;
  color: #404040;
`;

const ColumnItems = styled(motion.div)``;

const ColumnItem = styled(motion.div)`
  margin-bottom: 22.5px;
  padding-bottom: 22.5px;
  border-style: solid;
  border-color: #e5e5e5;
  border-bottom-width: 1px;
  :nth-last-child(1) {
    border-style: solid;
    border-color: ${props => props.dividerColor};
    border-bottom-width: 0px;
    @media (max-width: 860px) {
      border-bottom-width: 2px;
    }
  }
`;

const SmallerServicesListUpperContent = styled.div``;

const Title = styled.div`
  font-family: DM Serif Text;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  max-width: 575px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: #404040;
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.0325,
    },
  },
};

const columnItem = {
  hidden: { y: 20, x: -10, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};

const containerTwo = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.05,
      when: 'beforeChildren',
      staggerChildren: 0.0325,
    },
  },
};

const columnItemTwo = {
  hidden: { y: 20, x: -10, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};

const containerThree = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.1,
      when: 'beforeChildren',
      staggerChildren: 0.0325,
    },
  },
};

const columnItemThree = {
  hidden: { y: 20, x: -10, opacity: 0 },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
  },
};

export class SmallerServicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    const columns = () => {
      let elements = [];

      if (this.props.column_one_link_one_title.text) {
        const column1 = (
          <Column>
            <ColumnTitle>{this.props.column_one_title.text}</ColumnTitle>
            <ColumnItems
              variants={container}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              {this.props.column_one_link_one_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItem}
                  key={1}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_one_link_one_title.text}
                    link={linkStripper(this.props.column_one_link_one.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_one_link_two_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItem}
                  key={2}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_one_link_two_title.text}
                    link={linkStripper(this.props.column_one_link_two.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_one_link_three_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItem}
                  key={3}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_one_link_three_title.text}
                    link={linkStripper(this.props.column_one_link_three.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_one_link_four_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItem}
                  key={4}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_one_link_four_title.text}
                    link={linkStripper(this.props.column_one_link_four.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
            </ColumnItems>
          </Column>
        );
        elements.push(column1);
      }
      if (this.props.column_two_link_one_title.text) {
        const column2 = (
          <Column>
            <ColumnTitle>{this.props.column_two_title.text}</ColumnTitle>
            <ColumnItems
              variants={containerTwo}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              {this.props.column_two_link_one_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemTwo}
                  key={5}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_two_link_one_title.text}
                    link={linkStripper(this.props.column_two_link_one.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_two_link_two_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemTwo}
                  key={6}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_two_link_two_title.text}
                    link={linkStripper(this.props.column_two_link_two.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_two_link_three_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemTwo}
                  key={7}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_two_link_three_title.text}
                    link={linkStripper(this.props.column_two_link_three.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_two_link_four_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemTwo}
                  key={8}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_two_link_four_title.text}
                    link={linkStripper(this.props.column_two_link_four.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
            </ColumnItems>
          </Column>
        );
        elements.push(column2);
      }
      if (this.props.column_three_link_one_title.text) {
        const column3 = (
          <Column>
            <ColumnTitle>{this.props.column_three_title.text}</ColumnTitle>
            <ColumnItems
              variants={containerThree}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              {this.props.column_three_link_one_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemThree}
                  key={9}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_three_link_one_title.text}
                    link={linkStripper(this.props.column_three_link_one.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_three_link_two_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemThree}
                  key={10}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_three_link_two_title.text}
                    link={linkStripper(this.props.column_three_link_two.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_three_link_three_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemThree}
                  key={11}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_three_link_three_title.text}
                    link={linkStripper(this.props.column_three_link_three.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
              {this.props.column_three_link_four_title.text && (
                <ColumnItem
                  dividerColor={this.props.column_link_divider_color}
                  variants={columnItemThree}
                  key={11}
                >
                  <ButtonNotUnderlined
                    buttonText={this.props.column_three_link_four_title.text}
                    link={linkStripper(this.props.column_three_link_four.url)}
                    color={this.props.block_links_text_color}
                    arrowColor={this.props.block_color_theme}
                    fontSize={'16px'}
                  />
                </ColumnItem>
              )}
            </ColumnItems>
          </Column>
        );
        elements.push(column3);
      }
      if (
        this.props.column_four_link_one_title &&
        this.props.column_four_link_one_title.text
      ) {
        const rows4 = () => {
          let elem = [];
          if (this.props.column_four_link_two_title.text) {
            const row2 = (
              <ColumnItem
                dividerColor={this.props.column_link_divider_color}
                variants={columnItemThree}
                key={10}
              >
                <ButtonNotUnderlined
                  buttonText={this.props.column_four_link_two_title.text}
                  link={linkStripper(this.props.column_four_link_two.url)}
                  color={this.props.block_links_text_color}
                  arrowColor={this.props.block_color_theme}
                  fontSize={'16px'}
                />
              </ColumnItem>
            );
            elem.push(row2);
          }
          if (this.props.column_four_link_three_title.text) {
            const row3 = (
              <ColumnItem
                dividerColor={this.props.column_link_divider_color}
                variants={columnItemThree}
                key={11}
              >
                <ButtonNotUnderlined
                  buttonText={this.props.column_four_link_three_title.text}
                  link={linkStripper(this.props.column_four_link_three.url)}
                  color={this.props.block_links_text_color}
                  arrowColor={this.props.block_color_theme}
                  fontSize={'16px'}
                />
              </ColumnItem>
            );
            elem.push(row3);
          }
          if (this.props.column_four_link_four_title.text) {
            const row4 = (
              <ColumnItem
                dividerColor={this.props.column_link_divider_color}
                variants={columnItemThree}
                key={11}
              >
                <ButtonNotUnderlined
                  buttonText={this.props.column_four_link_four_title.text}
                  link={linkStripper(this.props.column_four_link_four.url)}
                  color={this.props.block_links_text_color}
                  arrowColor={this.props.block_color_theme}
                  fontSize={'16px'}
                />
              </ColumnItem>
            );
            elem.push(row4);
          }
          return elem;
        };

        const column4 = (
          <Column>
            <ColumnTitle>{this.props.column_four_title.text}</ColumnTitle>
            <ColumnItems
              variants={containerThree}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <ColumnItem
                dividerColor={this.props.column_link_divider_color}
                variants={columnItemThree}
                key={9}
              >
                <ButtonNotUnderlined
                  buttonText={this.props.column_four_link_one_title.text}
                  link={linkStripper(this.props.column_four_link_one.url)}
                  color={this.props.block_links_text_color}
                  arrowColor={this.props.block_color_theme}
                  fontSize={'16px'}
                />
              </ColumnItem>
              {rows4()}
            </ColumnItems>
          </Column>
        );

        elements.push(column4);
      }
      return elements;
    };
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={-100}
        intervalDelay={50}
      >
        <SmallerServicesListContainer>
          <SmallerServicesListContainerInner>
            <SmallerServicesListUpperContent>
              <Label color="#4758eb" text={this.props.label_text.text} />
              <Title>{this.props.title_text.text}</Title>
              {this.props.cta_text.text && (
                <ButtonNotUnderlined
                  buttonText={this.props.cta_text.text}
                  link={linkStripper(this.props.cta_link.url)}
                  color={this.props.cta_color}
                  arrowColor={this.props.cta_color}
                />
              )}
            </SmallerServicesListUpperContent>
            <ServicesListColumnContainerOuterContainer>
              <ServicesListColumnContainer>
                {columns()}
              </ServicesListColumnContainer>
            </ServicesListColumnContainerOuterContainer>
          </SmallerServicesListContainerInner>
        </SmallerServicesListContainer>
      </VisibilitySensor>
    );
  }
}

export default SmallerServicesList;
